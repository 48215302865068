import React from "react";
import {Col, Container, Nav, Row} from "react-bootstrap";
import FooterIcon from "../img/footer-icon.png";

const Footer = () => {
    return (
        <div className="footer-cont">
            <Container>
                <Row>
                    <Col xs={10}>
                        <Nav
                            activeKey="/home"
                            // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
                        >
                            <Nav.Item>
                                <Nav.Link href="https://americanseniorbenefits.com" className="menu-item-home">
                                    <img src={FooterIcon} alt="home icon" className="nav-icon" />
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="footer-item">
                                <Nav.Link href="https://americanseniorbenefits.com/who-we-are/" className="footer-link">WHO WE ARE</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="footer-item">
                                <Nav.Link href="https://americanseniorbenefits.com/what-sets-us-apart/" className="footer-link">WHAT SETS AS APART</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="footer-item">
                                <Nav.Link href="https://americanseniorbenefits.com/opportunities/" className="footer-link">OPPORTUNITIES</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="footer-item">
                                <Nav.Link href="https://americanseniorbenefits.com/map/" className="footer-link">FIND YOUR LOCAL OFFICE</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="footer-item">
                                <Nav.Link href="https://americanseniorbenefits.com/privacy-policy/" className="footer-link">PRIVACY POLICY</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="footer-item">
                                <Nav.Link href="" className="footer-link">CONTACT</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="footer-item">
                                <Nav.Link href="https://americanseniorbenefits.com" className="footer-link">SITEMAP</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                <Row className="footer-last">
                    <Col>
                        <p><span className="footer-bold">AMERICAN SENIOR BENEFITS</span> – 12722 SOUTH BLACKBOB ROAD OLATHE, KANSAS 66062</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer;