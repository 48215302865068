import React from "react";
import '../App.css';
import {Container, Row, Col, Nav, Navbar, Button} from "react-bootstrap";
import NavIcon from '../img/footer-icon.png';
import logo from '../img/logo-dark.png';

const Header = () => {
    return (
            < >
                <Container className="header-main">
                    <Row className="header-overlay">
                        <Col xs={6} md={8} lg={10}>
                            <Navbar expand="lg">
                                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto">
                                        <Nav.Item>
                                            <Nav.Link href="https://americanseniorbenefits.com"
                                                      className="menu-item-home">
                                                <img src={NavIcon} alt="home icon" className="nav-icon"/>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="menu-item">
                                            <Nav.Link href="https://americanseniorbenefits.com/who-we-are/"
                                                      className="menu-link">WHO WE ARE</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="menu-item">
                                            <Nav.Link href="https://americanseniorbenefits.com/what-sets-us-apart/"
                                                      className="menu-link">WHAT SETS AS APART</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="menu-item">
                                            <Nav.Link href="https://americanseniorbenefits.com/opportunities/"
                                                      className="menu-link">OPPORTUNITIES</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="menu-item">
                                            <Nav.Link href="https://americanseniorbenefits.com/map/"
                                                      className="menu-link">FIND YOUR LOCAL OFFICE</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        </Col>
                        <Col className="logo-cont">
                            <img src={logo} className="logo" alt="page logo"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} lg={6}>
                            <div className="header-text">
                                <h1>Members First Association Program</h1>
                                <div className="logo-div">
                                    <Button href="#signupForm" className="header-btn">LEARN MORE</Button>
                                    <img className="header-logo" alt="logo" src={logo} />
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={6}>

                        </Col>
                    </Row>
                </Container>
            </>

    );
};

export default Header;