import React from "react";
import {Col, Row, Container} from "react-bootstrap";
import JoinUsForm from "./JoinUsForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import TextIcon1 from '../img/text-icon-1.png';
import TextIcon2 from '../img/text-icon-2.png';
import TextIcon3 from '../img/text-icon-3.png';
import TextIcon4 from '../img/text-icon-4.png';
import TextIcon5 from '../img/text-icon-5.png';
import TextIcon6 from '../img/text-icon-6.png';


const Body = () => {
    return (
        <>
            <div className="first-section">
                <Container>
                    <Row>
                        <Col xs={12} md={6} lg={6}>
                            <div className="bluebox">
                                <h3>What Keeps Your Members up at Night?</h3>
                                <p><FontAwesomeIcon icon={solid('play')} className="red-tri"/><span className="bluebox-span">Outliving retirement savings?</span></p>
                                <p><FontAwesomeIcon icon={solid('play')} className="red-tri"/><span className="bluebox-span">Worrying</span> if they have the right health insurance?</p>
                                <p><FontAwesomeIcon icon={solid('play')} className="red-tri"/><span className="bluebox-span">Becoming ill or dying</span> unexpectedly?</p>
                                <p><FontAwesomeIcon icon={solid('play')} className="red-tri"/><span className="bluebox-span">Draining their savings</span> for home health care or nursing home care?</p>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                            <div className="makediff-box">
                                <h3>Make a Difference for Your Members.</h3>
                                <p><span className="">If you could provide solutions to these and other issues your members and their families face, would you do it?</span> Many associations have answered “Yes,” and selected American Senior Benefits (ASB) to administer insurance programs.</p>
                                <p><span>ASB is dedicated to serving those who want to solidify their futures.</span> We are independent. We understand that the choices our customers make are key life decisions. We work to help them find the right insurance solutions at affordable prices.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Container className="whatcando-box">
                                <Row>
                                    <Col>
                                        <h4>What Can American Senior Benefits Do:</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={6}>
                                        <div>
                                            <h4>For Your Association?</h4>
                                            <p><FontAwesomeIcon icon={solid('play')} className="red-tri"/>Enhance your member benefit program</p>
                                            <p><FontAwesomeIcon icon={solid('play')} className="red-tri"/>Increase member loyalty</p>
                                            <p><FontAwesomeIcon icon={solid('play')} className="red-tri"/>Additional benefits help attract new members</p>
                                            <p><FontAwesomeIcon icon={solid('play')} className="red-tri"/>Low-key educational approach-Only individuals requesting information will be contacted</p>
                                            <p><FontAwesomeIcon icon={solid('play')} className="red-tri"/>Marketing and administration provided at no cost to your association</p>
                                            <p><FontAwesomeIcon icon={solid('play')} className="red-tri"/>Membersandextendedfamilyservicedbylocal insurance professionals</p>
                                            <p><FontAwesomeIcon icon={solid('play')} className="red-tri"/>Educational website link provided</p>
                                            <p><FontAwesomeIcon icon={solid('play')} className="red-tri"/>Educational group webinars available</p>
                                            <p><FontAwesomeIcon icon={solid('play')} className="red-tri"/>Non-Dues Income may be available through convention participation and advertising</p>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={6}>
                                        <div>
                                            <h4>For Your Members?</h4>
                                            <p><FontAwesomeIcon icon={solid('play')} className="red-tri"/>Free comprehensive financial planning consultation available for members and extended family</p>
                                            <p><FontAwesomeIcon icon={solid('play')} className="red-tri"/>Nationwide group of trusted advisors with a relationship first approach</p>
                                            <p><FontAwesomeIcon icon={solid('play')} className="red-tri"/>American Senior Benefits is part of the Integrity Marketing family of companies – the nation’s largest independent distributor of life and health insurance products</p>
                                            <p><FontAwesomeIcon icon={solid('play')} className="red-tri"/>ASB works with over 220 insurance carriers that provide individual policies which are fully portable</p>
                                            <p><FontAwesomeIcon icon={solid('play')} className="red-tri"/>Programs available for association members that are employers</p>
                                            <p><FontAwesomeIcon icon={solid('play')} className="red-tri"/>SAGE Scholars Program – helps students fund private education</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={6}>
                        <Container className="wehelp-box">
                            <Row>
                                <Col>
                                    <h5>We help your members build a foundation for security by educating them on the following:</h5>
                                </Col>
                            </Row>
                            <Row className="wehelp-div">
                                <Col xs={2}>
                                    <img src={TextIcon1} className="text-icon" alt="section icon"/>
                                </Col>
                                <Col>
                                    <p><span>Medicare and Medicare Supplements </span>— Medicare doesn’t cover everything. That’s why we offer Medicare Supplements and other Medicare solutions, including Medicare Part D plans (prescription drugs.)</p>
                                </Col>
                            </Row>
                            <Row className="wehelp-div">
                                <Col xs={2}>
                                    <img src={TextIcon2} className="text-icon second" alt="section icon"/>
                                </Col>
                                <Col>
                                    <p><span>Life Insurance </span>— We offer a selection of Whole Life, Term, Universal Life and Graded Benefits.</p>
                                </Col>
                            </Row>
                            <Row className="wehelp-div">
                                <Col xs={2}>
                                    <img src={TextIcon3} className="text-icon" alt="section icon"/>
                                </Col>
                                <Col>
                                    <p><span>Long Term Care Solutions </span>— Research shows more than half of those turning 65 now will need long-term care. We have a range of solutions for future expenses.</p>
                                </Col>
                            </Row>
                            <Row className="wehelp-div">
                                <Col xs={2}>
                                    <img src={TextIcon4} className="text-icon" alt="section icon"/>
                                </Col>
                                <Col>
                                    <p><span>Annuities </span>— Having a steady, safe, stream of income that can’t be outlived just makes sense. Our selection of annuities can help your members achieve just that.</p>
                                </Col>
                            </Row>
                            <Row className="wehelp-div">
                                <Col xs={2}>
                                    <img src={TextIcon5} className="text-icon" alt="section icon"/>
                                </Col>
                                <Col>
                                    <p><span>Retirement Planning </span>— Our affiliated agents can help your members create a retirement strategy that is manageable, realistic and beneficial in the long run.</p>
                                </Col>
                            </Row>
                            <Row className="wehelp-div">
                                <Col xs={2}>
                                    <img src={TextIcon6} className="text-icon" alt="section icon"/>
                                </Col>
                                <Col>
                                    <p><span>Specialized Health Care </span>— We offer a range of solutions that address dental and vision care as well as critical issues such as hospitalization, disability, cancer, stroke and heart attack.</p>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                        <div className="form-div">
                            <h5 className="form-title">For Further Information on how we can serve your members...</h5>
                            <JoinUsForm />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
};

export default Body;