import React, {useEffect, useState} from "react";

import {Form, FormLabel} from "react-bootstrap";
import {Button, InputGroup, Row, Col} from "react-bootstrap";

const JoinUsForm = () => {
    const [group_name, setGroupName] = useState("");
    // const [group_contact_name, setGroupContactName] = useState("");
    const [group_contact_first_name, setGroupContactFirstName] = useState("");
    const [group_contact_last_name, setGroupContactLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [notes, setNotes] = useState("");
    const [message, setMessage] = useState("");
    const [phone_type, setPhoneType] = useState("");

    const API_SERVER = 'https://asbaffinity.com';
    const API_URL = `${API_SERVER}/public/groups/signup`;

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const statusValue = queryParams.get('status');
        if (statusValue && statusValue === 'success') {
            setMessage("Thank you! We received your request.");
        } else if (statusValue && statusValue === 'error') {
            setMessage("Sorry. Some error occurred.");
        }
    }, []);

    return(
        <div id="signupForm" className="form-cont">
            <Form  method="POST" action={API_URL}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <p className="request-message">{message}</p>                        
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Group className="mb-3" controlId="formGroupName">
                            <Form.Label className="form-label">Group Name <sup className="form-req">*</sup></Form.Label>
                            <Form.Control type="text"
                                        required
                                        className="form-input"
                                        value={group_name}
                                        name={'group_name'}
                                        onChange={(e) => setGroupName(e.target.value)}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Form.Group className="mb-3" controlId="formGroupContactFirstName">
                            <Form.Label className="form-label">Contact First Name <sup className="form-req">*</sup></Form.Label>
                            <Form.Control type="text"
                                        required
                                        className="form-input"
                                        value={group_contact_first_name}
                                        name={'group_contact_first_name'}
                                        onChange={(e) => setGroupContactFirstName(e.target.value)}/>
                        </Form.Group>                        
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Form.Group className="mb-3" controlId="formGroupContactLastName">
                            <Form.Label className="form-label">Contact Last Name <sup className="form-req">*</sup></Form.Label>
                            <Form.Control type="text"
                                        required
                                        className="form-input"
                                        name={'group_contact_last_name'}
                                        value={group_contact_last_name}
                                        onChange={(e) => setGroupContactLastName(e.target.value)}/>
                        </Form.Group>                        
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Group className="mb-3" controlId="formPhone">
                            <Form.Label className="form-label">Phone Number <sup className="form-req">*</sup></Form.Label>
                            <FormLabel className="form-label">Cell</FormLabel>
                            <input type="radio"
                                className="radio_input"
                                name="phone_type"
                                value="cell"
                                onChange={(e) => setPhoneType(e.target.value)}
                            />
                            <FormLabel className="form-label">Home</FormLabel>
                            <input type="radio"
                                className="radio_input"
                                name="phone_type"
                                value="home"
                                onChange={(e) => setPhoneType(e.target.value)}
                            />
                            <FormLabel className="form-label">Work</FormLabel>
                            <input type="radio"
                                className="radio_input"
                                name="phone_type"
                                value="work"
                                onChange={(e) => setPhoneType(e.target.value)}
                            />
                            <Form.Control type="number"
                                        required
                                        className="form-input"
                                        value={phone}
                                        name={'group_contact_phone'}
                                        onChange={(e) => setPhone(e.target.value)}/>
                        </Form.Group>                        
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Label className="form-label">Email <sup className="form-req">*</sup></Form.Label>
                            <Form.Control type="email"
                                        required
                                        className="form-input"
                                        value={email}
                                        name={'group_contact_email'}
                                        onChange={(e) => setEmail(e.target.value)}/>
                        </Form.Group>                        
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Group className="mb-3" controlId="formNotes">
                            <Form.Label className="form-label">Notes</Form.Label>
                            <Form.Control as="textarea"
                                        rows={3}
                                        className="form-input"
                                        value={notes}
                                        name={'group_notes'}
                                        onChange={(e) => setNotes(e.target.value)}/>
                        </Form.Group>                        
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="button-cont">
                            <Button variant="primary" type="submit" className="button-join">
                                CONTACT US
                            </Button>
                        </div>              
                        <p className="form-footer">
                            ASB LOOKS FORWARD TO
                            THE OPPORTUNITY TO SERVE YOUR ORGANIZATION AND ITS MEMBERS.
                        </p>          
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default JoinUsForm;